import React, { createContext, useContext, useEffect } from "react";
import { tv } from "tailwind-variants";

export type TooltipProps = {
  text?: React.ReactNode;
  domRect?: DOMRect;
  position?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "rightTop"
    | "rightBottom"
    | "leftTop"
    | "leftBottom";
  size?: "small" | "medium" | "large" | "auto";
  className?: string;
  open?: boolean;
  setTooltipProps?: React.Dispatch<React.SetStateAction<TooltipProps>>;
};

export const TooltipContext = createContext<TooltipProps>({});

const tvTooltip = tv({
  base: "fixed bg-slate-600 text-slate-50 p-2 rounded-md shadow-md z-[51] text-center text-xs font-normal [text-wrap:pretty] whitespace-normal",
  variants: {
    position: {
      left: "transform -translate-y-1/2 -translate-x-full -ml-1",
      right: "transform -translate-y-1/2 ml-1",
      top: "transform -translate-x-1/2 -translate-y-full -mt-1",
      bottom: "transform -translate-x-1/2 mt-1",
      rightTop: "transform -translate-y-full -mt-1",
      rightBottom: "transform mt-1",
      leftTop: "transform -translate-y-full -mt-1 -translate-x-full -ml-1",
      leftBottom: "transform mt-1 -translate-x-full -ml-1",
    },
    size: {
      small: "w-36 lg:w-48",
      medium: "w-48 lg:w-60",
      large: "w-72 lg:w-96",
      auto: "w-auto max-w-96",
    },
  },
  defaultVariants: {
    size: "large",
  },
});

// This component is displayed wi
export function TooltipOverlay() {
  const context = useContext(TooltipContext);

  if (!context.domRect) {
    return null;
  }

  let y = context.domRect.y;
  let x = context.domRect.x;

  switch (context.position) {
    case "right":
    case "left":
      y = context.domRect.y + context.domRect.height / 2;
      break;
    case "bottom":
    case "leftBottom":
    case "rightBottom":
      y = context.domRect.y + context.domRect.height;
      break;
  }

  switch (context.position) {
    case "top":
    case "bottom":
      x = context.domRect.x + context.domRect.width / 2;
      break;
    case "right":
    case "rightTop":
    case "rightBottom":
      x = context.domRect.x + context.domRect.width;
      break;
  }

  return (
    context.open && (
      <div
        className={tvTooltip({
          position: context.position,
          size: context.size,
          className: context.className,
        })}
        style={{ top: y, left: x }}
      >
        <span>{context.text}</span>
      </div>
    )
  );
}

export function Tooltip({
  text,
  children,
  position = "left",
  size,
  className,
}: {
  text: string | React.ReactNode;
  children: React.ReactNode;
  position?: TooltipProps["position"];
  size?: TooltipProps["size"];
  className?: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);

  const [showTooltip, setShowTooltip] = React.useState(false);

  const context = useContext(TooltipContext);

  const { setTooltipProps } = context;

  useEffect(() => {
    if (showTooltip) {
      const boundingRect = ref.current?.getBoundingClientRect();

      if (!boundingRect) {
        return;
      }

      setTooltipProps?.(prev => ({
        ...prev,
        open: true,
        text,
        position,
        size,
        className,
        domRect: boundingRect,
      }));
    } else {
      setTooltipProps?.(prev => ({
        ...prev,
        open: false,
      }));
    }
  }, [text, position, size, className, showTooltip, setTooltipProps]);

  return text ? (
    <div
      className="relative inline-block align-middle"
      id="tooltip"
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      onClick={() => {
        setShowTooltip(true);
      }}
      ref={ref}
    >
      {children}
    </div>
  ) : (
    children
  );
}
